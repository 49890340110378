<template>
  <div class="carousel-box">
    <div>

    <div class="top-box">
    <VueSlickCarousel
        lazyLoad
        :draggable="false"
        :infinite="false"
        class="carousel-top"
        ref="c1"
        :asNavFor="$refs.c2"
        :focusOnSelect="true">
      <div class="img-box" ref="c3" v-for="(item,index) in val.imglist" :key="item.name">

        <div class="box-box">
          <v-img
              class="img"
              :attachment-id="item.url"
              :default-img="require('@/assets/img/kctp.png')"
          >
          </v-img>
        </div>
        <div class="text">{{Number(index)+1}}/{{val.imglist.length}}页</div>
<!--        <div class="carousel-top-btn left" @click="changeLeft" :class="num===0? 'bak':''">
          <img :src="other_img.img2" alt="图片" />
        </div>
        <div class="carousel-top-btn right" @click="changeRight" :class="num===val.imglist.length? 'bak':''">
          <img  :src="other_img.img1" alt="图片" />
        </div>-->
      </div>
      <template #prevArrow="arrowOption" >
        <div class="carousel-top-btn left ">
          <img :src="other_img.img2" alt="图片" />
        </div>
      </template>
      <template #nextArrow="arrowOption" >
        <div class="carousel-top-btn right">
          <img  :src="other_img.img1" alt="图片" />
        </div>
      </template>

    </VueSlickCarousel>
    </div>


      <div class="bottom-box">
       <VueSlickCarousel
        lazyLoad
        :draggable="false"
        :infinite="false"
        class="carousel-bottom"
        ref="c2"
        :asNavFor="$refs.c1"
        :slidesToShow="7"
        :focusOnSelect="true">
      <div  v-for="item in val.imglist" :key="item.name">
        <v-img
            class="img"
            :attachment-id="item.url"
            :default-img="require('@/assets/img/kctp.png')"
        >
        </v-img>
      </div>
      <template #prevArrow="aee" >
        <div class="carousel-bottom-btn left btn" >
          <img class="bottom-img" :src="other_img.img4" alt="图片" />
        </div>
      </template>
      <template #nextArrow="aee" >
        <div class="carousel-bottom-btn right btn" >
          <img class="bottom-img" :src="other_img.img3" alt="图片" />
        </div>
      </template>

    </VueSlickCarousel>
      <div class="remind" v-if="val.type==5">温馨提示：如有涉及侵权，请联系平台下架</div>
      </div>

    </div>




  </div>
<!--  -->
</template>
<script>
import VImg from "@/components/VImg";
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  //props:['val'],
  props:{
    val:{
      type: Object,
      default() {
        return { imglist:[],type:'' }
      }
    }
  },
  data() {
    return {
      num:0,
      other_img:{
        img1:require("@/assets/img/program5.png"),
        img2:require("@/assets/img/program6.png"),
        img3:require("@/assets/img/program8.png"),
        img4:require("@/assets/img/program9.png"),
      },
    };
  },
  mounted() {
    console.log(this.val,'val')
  },
  components:{
    VImg,
    VueSlickCarousel
  },
  methods: {
    changeLeft(){
      this.$refs.c1.prev()
    },
    changeRight(){
      console.log('111111111')
      this.$refs.c1.next()
    }
  },
};
</script>

<style scoped lang="scss">
.carousel-box{
  ::v-deep{
    .slick-slider{
      background: #FFFFFF;
      z-index: 10;
    }
  }


  .carousel-bottom{
    position:relative;
    height: 300px;
    padding-top:50px;
    ::v-deep{

      .slick-list{
        margin: 0 auto;
        width: 1080px;

        .slick-slide{
          >div{
            >div{
              width: 126.5px;
              height: 179.5px;
              display: flex!important;
              justify-content: center;
              align-items: center;
              .img{
                display: block;
                max-width: 126.5px;
                max-height: 179.5px;
                border: 1px solid #EEEEEE;
                box-shadow: 0px 0px 6px #EEEEEE;
              }

            }
          }
        }

      }
      .slick-next:before{
        display: none;
      }
      .slick-prev:before{
        display: none;
      }
    }
    .left{
      position: absolute;
      left: 0;
      top: 140px;
    }
    .right{
      position: absolute;
      right: 0;
      top: 140px;
    }
    .carousel-bottom-btn{
      width: 36px;
      height: 109px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        display: block;
        width: 36px;
        height: 78px;
      }
    }

    .btn{
      border: 1px solid #F6F8FB;
      box-shadow: 0px 0px 6px #F6F8FB;
    }





  }

  .bottom-box{
    position: relative;
    .remind{
      position: absolute;
      bottom: 16px;
      left: 50px;
      font-size: 16px;
      color: #999999;
      z-index: 20;
    }
  }



  .carousel-top::v-deep{
    .slick-track{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .carousel-top{
    position:relative;
    margin-bottom:16px;
    ::v-deep{
      .slick-list{
        background: #FFFFFF;
        margin-bottom: 16px;
      }
      .slick-next:before{
        display: none;
      }
      .slick-prev:before{
        display: none;
      }
    }
    .right{
      position: absolute;
      right: 0;
      top:508px;
    }
    .left{
      position: absolute;
      left: 0;
      top:508px;
    }
    .img-box{
      height: 1017px;
      display: flex!important;
      justify-content: center;
      align-items: center;
      position: relative;
      >.box-box{
        width: 950px;
        height: 900px;
        display: flex;
        justify-content: center;
        align-items: center;
        >.img{
          display: block;
          width: auto;
          height: auto;
          max-width: 950px;
          max-height: 900px;
          border: 1px #EEEEEE solid;
          box-sizing: border-box;
          z-index: 11;
        }
      }

      .text{
        width: 1280px;
        position: absolute;
        left: 0;
        bottom: 24px;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #333333;
      }
    /*  .bak{
        background: #000000;
        opacity: 0.05;
        border-radius: 0px 4px 4px 0px;
      }*/
    }
    .carousel-top-btn{
      width: 90px;
      height: 271px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      >img{
        display: block;
        width: 34px;
        height: 61px;
        z-index: 10;
       // margin-right: 0;
      }
    }

  }

}

</style>





